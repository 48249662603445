// blogs
export const fetchBlogs = (
    callback = () => {},
    perPage = 100,
    page = 1,
    options = {
      categoryId: null,
      userId: null
    }
  ) => {
  const categoryParam = options.categoryId ? `&categories=${options.categoryId}` : '';
  const authorParam = options.userId ? `&author=${options.userId}` : '';
  const blogsUrl = `${window.apiUrl}/posts?page=${page}&per_page=${perPage}${categoryParam}${authorParam}`;
  const staffsUrl = `${window.apiUrl}/staffs`;
  const categoriesUrl = `${window.apiUrl}/categories`;

  Promise.all([
    fetch(blogsUrl).then(response => response.json()),
    fetch(staffsUrl).then(response => response.json()),
    fetch(categoriesUrl).then(response => response.json())
  ])
  .then(([blogs, staffs, categories]) => {
    const _blogs = blogs.map((blog) => {
      const _staff = staffs.find(v => v.acf['acf-user-id'] === blog.author);
      const _categories = blog.categories.map(id => categories.find(v => v.id === id)).map(v => {
        return {
          id: v.id,
          name: v.name,
          url: v.link
        }
      });

      return {
        url: blog.link,
        title: blog.title.rendered,
        thumbUrl: blog.featured_image_url,
        date: blog.date,
        categories: _categories,
        author: {
          id: _staff.id,
          url: _staff.link,
          thumbUrl: _staff.acf['acf-image'],
          name: {
            jp: _staff.acf['acf-name-jp'],
            en: _staff.acf['acf-name-en'],
          },
          job: _staff.acf['acf-job'],
          role: _staff.acf['acf-role']
        }
      }
    });

    callback(_blogs);
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
};

// staffs
export const fetchStaffs = (callback, perPage = 100 ) => {
  fetch(`${window.apiUrl}/staffs?per_page=${perPage}&order=asc&orderby=id`)
  .then(response => response.json())
  .then((staffs) => {
    const _staffs = staffs.map((staff) => {
      return {
        id: staff.id,
        url: staff.link,
        thumbUrl: staff.acf['acf-image'],
        name: {
          jp: staff.acf['acf-name-jp'],
          en: staff.acf['acf-name-en'],
        },
        job: staff.acf['acf-job'],
        role: staff.acf['acf-role']
      }
    });

    callback(_staffs);
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
};

// companies
export const fetchCompanies = (callback, perPage = 100 ) => {
  fetch(`${window.apiUrl}/companies?per_page=${perPage}`)
  .then(response => response.json())
  .then((companies) => {
    const _companies = companies.map((company) => {
      return {
        id: company.id,
        name: company.acf['acf-name'],
        url: company.acf['acf-url'],
        overview: company.acf['acf-overview'],
        thumbUrl: company.acf['acf-image'],
      }
    });

    callback(_companies);
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
};

// banners
export const fetchBanners = (callback, perPage = 100 ) => {
  fetch(`${window.apiUrl}/banners?per_page=${perPage}`)
  .then(response => response.json())
  .then((banners) => {
    const _banners = banners.map((banner) => {
      return {
        id: banner.id,
        name: banner.acf['acf-name'],
        url: banner.acf['acf-url'],
        isBlank: banner.acf['acf-is-blank'],
        thumbUrl: banner.acf['acf-image'],
      }
    });

    callback(_banners);
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
};

// Categories
export const fetchCategories = (callback, perPage = 100 ) => {
  fetch(`${window.apiUrl}/categories`)
  .then(response => response.json())
  .then((categories) => {
    const _categories = categories.filter(category => category.count).map(category => {
      return {
        ...category,
        url: category.link
      }
    });

    callback(_categories);
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
};

// Popular Posts
export const fetchRankingBlogs = (callback, postLimits = 5) => {
  const rankingsUrl = `${window.siteUrl}/wp-json/wordpress-popular-posts/v1/popular-posts?limit=${postLimits}&range=all`;
  fetch(rankingsUrl).then(response => response.json())
  .then((rankingBlogs) => {
    const _rankingBlogs = rankingBlogs.map((rankingBlog, i) => {
      return {
        rank: i + 1,
        title: rankingBlog.title.rendered,
        thumbUrl: rankingBlog.featured_image_url,
        date: rankingBlog.date,
        url: rankingBlog.link
      }
    });

    callback(_rankingBlogs);
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
}